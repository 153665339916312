<template>
  <div class="category">
    <div ref="tcktinfo" class="info">
      <div class="item" @click="goToGuide">{{ guide.title }}</div>
      <div class="item">
        <template v-if="user">
          <div class="tkt-info">
            <div class="desc">
              <div class="img" v-if="user.avatar">
                <img :src="imgURL + user.avatar" alt="" />
              </div>
              <span v-else class="no-img">{{
                user.name.charAt(0) || guide.created_by_user.name.charAt(0)
              }}</span>
              <div class="tck-desc">
                <div class="name">
                  {{ user.name || guide.created_by_user.name }}
                </div>
                <div class="user">
                  <span class="model">{{ timeSince }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="guide.updated_at">
          last updated {{ timeSince }}
        </template>
        <template v-else> undefined </template>
      </div>
      <div class="options" v-if="canEdit" @click="edit = !edit">
        <img src="/assets/images/black_dots.svg" alt="" />
        <options-modal
          v-if="edit"
          :options="['edit']"
          @edit="editGuide = true"
          @delete="del()"
          v-click-outside="closeOptions"
        />
      </div>
    </div>
    <edit-modal
      :title="`Edit Guide`"
      :items="toEdit"
      v-if="editGuide"
      @close="editGuide = false"
      @submitData="editGuides"
    />
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import optionsModal from "@/components/Ui/General/Options.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
export default {
  props: {
    guide: {
      required: false,
      type: Object,
    },
    allChecked: {
      required: true,
      type: Boolean,
    },
    canEdit: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      tosites: false,
      guideSrch: "",
      searchResult: [],
      editGuide: false,
    };
  },

  components: {
    optionsModal,
    EditModal,
  },

  computed: {
    toEdit() {
      return [
        {
          type: "text",
          label: "Title",
          value: this.guide.title,
        },
      ];
    },

    timeSince() {
      const last_updated = Date.parse(this.guide.updated_at);
      const seconds = Math.floor((new Date() - last_updated) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return `at ${this.guide.updated_at.split("T")[0]}`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return `at ${this.guide.updated_at.split("T")[0]}`;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        if (Math.floor(interval) > 5)
          return `at ${this.guide.updated_at.split("T")[0]}`;
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "day ago" : "days ago"
        }`;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "hour ago" : "hours ago"
        }`;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "minute ago" : "minutes ago"
        }`;
      }
      return `${Math.floor(seconds)} ${
        Math.floor(seconds) === 1 ? "second ago" : "seconds ago"
      }`;
    },

    user() {
      if (this.guide.updated_by_user) return this.guide.updated_by_user;
      return this.guide.created_by_user;
    },
  },

  methods: {
    closeOptions() {
      this.edit = false;
    },
    goToGuide() {
      this.$router.push({
        path: `/equipment/guides/${this.$route.params.catid}/step-list/${this.guide.id}`,
      });
    },
    async del() {
      const res = await axiosInstance.delete(`guide/${this.guide.id}`);

      if (res.status === 200) {
        this.emitter.emit("alert", res.data.message);
        this.$emit("updateTicket");
      }
    },
    async editGuides(data) {
      const org = JSON.parse(localStorage.getItem("organization"));
      const toSend = {
        title: data.Title,
        org_id: org.id,
        category_id: this.$route.params.catid,
        guide_id: this.guide.id,
      };

      const response = await axiosInstance.post(`guide`, toSend);
      if (response) {
        this.emitter.emit("alert", response.data.message);
        this.$emit("updateTicket");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
input[type="checkbox"] {
  opacity: 0.5;
}
.overlay {
  width: 100%;
  height: 80px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #f2f5f8;
  .bar {
    width: 15%;
    height: 30px;
    background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
    background-size: 400% 400%;
    animation: load 1s ease infinite;
    border-radius: 30px;
    &.large {
      width: 20%;
      background: none;
      animation: unset;
      border-radius: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .round {
        width: 30px;
        height: 30px;
        background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
        background-size: 400% 400%;
        animation: load 0.5s ease infinite;
        border-radius: 50%;
      }
      .txt {
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 2px;

        div {
          background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
          background-size: 400% 400%;
          animation: load 0.5s ease infinite;
          border-radius: 30px;
        }
        .top {
          height: 17px;
        }
        .bottom {
          height: 11px;
        }
      }
    }
  }
}
.category {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 25px;
  background: white;
  border-bottom: solid 1px #f2f5f8;
  cursor: pointer;

  &.notsite {
    background: var(--primary-hover);
  }
  .info {
    width: 98%;
    display: flex;
    .item {
      width: calc(98% / 2);
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      &.description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .tkt-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
          }
        }
        .no-img {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          background: var(--primary-color);
        }
        .desc {
          display: flex;
          align-items: center;
          gap: 10px;

          .tck-desc {
            .name {
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .time {
              color: #cad6e2;
              font-weight: 400;
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
  .options {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    user-select: none;
    .dropdown-options {
      top: 60%;
    }
  }
}
</style>